import { memo } from 'react';
import { Form, Select } from 'antd';
import { useTranslate } from 'shared/lib/i18n';
import { useGetAppsQuery } from 'entities/apps';

interface IAppsSelect {
	name: string[];
	onChange: (value: any) => void;
	initialValue?: any;
}

const AppSelectComponent = ({ name, onChange, initialValue }: IAppsSelect) => {
	const t = useTranslate();
	const { apps } = useGetAppsQuery(
		{
			page: 1,
			appsPerPage: 1000
		},
		{
			selectFromResult: ({ data, isLoading }) => ({
				apps: data?.docs.map((app) => ({ value: app.id, label: app.name })) ?? [],
				isLoading
			}),
			refetchOnMountOrArgChange: true
		}
	);

	return (
		<Form.Item
			label={
				<span>
					{t('helper')}
					<span className='ml-1 text-error'>*</span>
				</span>
			}
			name={name}
			initialValue={initialValue}
			rules={[{ required: true, message: t('error.required_field') }]}
		>
			<Select onChange={onChange} options={apps} optionFilterProp='label' showSearch />
		</Form.Item>
	);
};

export const AppSelect = memo(AppSelectComponent);
