export const selectEdges = (state: RootState) => {
	return state.app?.edges;
};

export const selectActions = (state: RootState) => {
	return state.app?.actions;
};

export const selectCurrentTab = (state: RootState) => {
	return state.app?.currentTab;
};

export const selectIsActionRemoved = (state: RootState) => {
	return state.app?.isActionRemoved;
};

export const selectChangedUserSettings = (state: RootState) => {
	return state.app?.changedUserSettings;
};

export const selectSlugs = (state: RootState) => {
	return state.app?.actions.map((action) => action.id);
};

export const selectAppSettings = (state: RootState) => {
	return {
		...state.app,
		author: state.app?.author,
		isPrivate: state.app?.isPrivate,
		tags: state.app?.tags,
		editorDisabled: state.app?.editorDisabled
	};
};

export const selectUserSettings = (state: RootState) => {
	return state.app?.settings;
};

export const selectHasSettingsError = (state: RootState) => {
	return state.app?.hasSettingsError;
};

export const selectHasUserSettingsError = (state: RootState) => {
	return state.app?.hasUserSettingsError;
};

export const selectActiveAction = (state: RootState) => {
	const selected = state.app?.actions.find((action) => action.selected);
	return selected?.data;
};
