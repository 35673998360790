import React, { memo, useMemo } from 'react';
import { PlayCircleFilled, EyeFilled } from 'shared/ui/Icons';
import { Button, Popover } from 'antd';
import { useAppDispatch, useAppSelector } from 'shared/model';
import { selectCurrentProject } from 'entities/project';
import { setRunProject, setViewProject } from '../../../model/slice';
import { selectActions, selectAppSettings } from '../../../model/selectors';
import { useTranslate } from 'shared/lib/i18n';

interface INodeActions {
	currentSlug: string;
}

const NodeActionsComponent = ({ currentSlug }: INodeActions) => {
	const t = useTranslate();
	const dispatch = useAppDispatch();
	const { project, currentArtefact } = useAppSelector(selectCurrentProject);
	const { initialRegenerateIndex } = useAppSelector(selectAppSettings);
	const actions = useAppSelector(selectActions);
	const currentActionIndex = actions?.findIndex((action) => action.data.slug === currentSlug) - 1;

	const disabled = useMemo(() => {
		if (project?.generating) {
			return true;
		}

		if (!currentActionIndex) {
			return false;
		}

		if (!project && currentActionIndex) {
			return true;
		}

		if (!!initialRegenerateIndex && initialRegenerateIndex - 1 < currentActionIndex) {
			return true;
		}

		if (project && project.artefacts.length < currentActionIndex) {
			return true;
		}
		if (!!initialRegenerateIndex && initialRegenerateIndex < currentActionIndex) {
			return true;
		}
		return false;
	}, [currentActionIndex, initialRegenerateIndex, project]);

	return (
		<div className='flex gap-x-1'>
			<Button
				type='text'
				size='small'
				icon={<EyeFilled className='!text-[10px]' />}
				onClick={() => dispatch(setViewProject(currentSlug))}
				disabled={!project?.artefacts.find((artefact) => artefact.slug === currentSlug)}
				className='bg-gray-bg hover:!bg-gray-border-dark !w-[20px] !h-[20px] hover:opacity-90 text-gray-secondry'
			></Button>
			<Popover content={disabled ? <span>{t('run_previous_warning')}</span> : <span>{t('click_for_run_step')}</span>}>
				<Button
					type='text'
					size='small'
					icon={<PlayCircleFilled className='!text-[10px]' />}
					onClick={() => dispatch(setRunProject(currentSlug))}
					disabled={disabled}
					className='bg-gray-bg hover:!bg-gray-border-dark !w-[20px] !h-[20px] hover:opacity-90 text-gray-secondry'
				></Button>
			</Popover>
		</div>
	);
};

export const NodeActions = memo(NodeActionsComponent);
