import { memo } from 'react';
import { Position, NodeProps } from 'reactflow';
import cn from 'classnames';
import LimitedHandle from '../../CustomHandle';
import { NodeActions } from '../../NodeActions';
import { ENodeHandleVariants } from '../../../../model/types';
import { ApiOutlined } from 'shared/ui/Icons';
import { Divider, Typography } from 'antd';
import { useTranslate } from 'shared/lib/i18n';

const ApiTemplateCallActionComponent = (props: NodeProps) => {
	const t = useTranslate();
	const { data, selected } = props;
	return (
		<div
			className={cn(
				'p-4 rounded-md bg-white border-2 border-solid border-font-quaternary min-w-[168px] max-w-[168px] min-h-[14px] max-h-[90px] relative',
				{
					'border-primary-active shadow-node': selected,
					'!border-error': data.status === 'error'
				}
			)}
		>
			<div>
				<div className='flex gap-x-2 items-center'>
					<div className='bg-fill-tertiary rounded min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] flex items-center justify-center'>
						<ApiOutlined className='text-blue-800 text-[17px]' />
					</div>
					<Typography.Paragraph
						rootClassName='text-sm font-medium text-black !m-0 !line-clamp-1'
						ellipsis={{
							rows: 2,
							expanded: false
						}}
					>
						{t('api_template_call')}
					</Typography.Paragraph>
					<div className='ml-auto'>{!!data.slug && !data.isMapCallItem && <NodeActions currentSlug={data.slug} />}</div>
				</div>
				<Divider style={{ margin: '8px 0' }} />
				<div className='text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-max min-w-[60px] text-center overflow-hidden text-ellipsis whitespace-nowrap'>
					{data.slug}
				</div>
			</div>

			<LimitedHandle
				type='target'
				position={data.isMapCallItem ? Position.Left : Position.Top}
				className='opacity-0'
				id={ENodeHandleVariants.SOURCE_HANDLE}
				isConnectable={true}
			/>
			{!data.isMapCallItem && (
				<LimitedHandle
					type='source'
					position={Position.Bottom}
					className='!rounded-full !border-solid !border-primary !border-2 !w-2 !h-2 !bg-white !-bottom-[5px]'
					isConnectable={!data.nextSlug}
				/>
			)}
		</div>
	);
};

export const ApiTemplateCallAction = memo(ApiTemplateCallActionComponent);
