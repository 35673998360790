import React, { memo } from 'react';
import { Button, Divider, Form, Input, Switch } from 'antd';
import { EAppFormFields, TUserInputItemFields } from '../../../../model/types';
import { DeleteOutlined, PlusOutlined } from 'shared/ui/Icons';
import { validateNotEmpty } from 'shared/lib';
import { useTranslate } from 'shared/lib/i18n';

type TSelectFields = TUserInputItemFields & {
	handleAddSelectOption: () => void;
	handleRemoveOption: (name: number) => void;
};
const SelectFieldsComponent = ({
	fieldData,
	onChange,
	path,
	handleRemoveFields,
	index,
	handleRemoveOption,
	handleAddSelectOption
}: TSelectFields) => {
	const t = useTranslate();

	return (
		<div>
			<Divider style={{ margin: '8px 0' }} />
			<div className='flex w-full font-bold gap-x-1.5 mb-2 mt-4'>
				<div>{t('input.select')}</div>
				<DeleteOutlined
					className='text-error'
					onClick={() => {
						handleRemoveFields(index);
					}}
				/>
			</div>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, index, EAppFormFields.BLOCK_TYPE]}
				initialValue={fieldData[EAppFormFields.BLOCK_TYPE] ?? 'select'}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={t('key_name')}
				name={[...path, index, EAppFormFields.NAME]}
				initialValue={fieldData[EAppFormFields.NAME]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') },
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={t('label')}
				name={[...path, index, EAppFormFields.LABEL]}
				initialValue={fieldData[EAppFormFields.LABEL]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') },
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.LABEL], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('default_value')}</span>}
				name={[...path, index, EAppFormFields.DEFAULT_VALUE]}
				initialValue={fieldData[EAppFormFields.DEFAULT_VALUE]}
				rules={[
					{
						required: fieldData[EAppFormFields.REQUIRED],
						message: t('error.required_field')
					},
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') },
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.DEFAULT_VALUE], e.target.value)} />
			</Form.Item>
			<Form.List
				name={[...path, index, EAppFormFields.OPTIONS]}
				initialValue={fieldData[EAppFormFields.OPTIONS]}
				rules={[
					{
						validator: async (_, names) => {
							if (!names || names.length < 1) {
								return Promise.reject();
							} else {
								return Promise.resolve();
							}
						},
						message: t('error.options_required')
					}
				]}
			>
				{(fields, { add, remove }, { errors }) => {
					return (
						<div>
							<div className='mb-2 font-medium'>
								<span>{t('options')}</span>
								{!!errors.length && (
									<div>
										<Form.ErrorList errors={errors} className='text-error' />
									</div>
								)}
							</div>
							{fields.map(({ key, name, ...restField }, index) => {
								return (
									<div key={key} className='mb-4'>
										<div className='flex justify-between items-center mb-2.5'>
											<div>
												{index + 1} <span className='ml-1 text-error'>*</span>
											</div>
											<DeleteOutlined
												className='text-error'
												onClick={() => {
													handleRemoveOption(name);
													remove(name);
												}}
											/>
										</div>
										<div key={key} className='flex flex-col grow gap-y-2.5'>
											<Form.Item
												{...restField}
												className='mb-0'
												name={[name, EAppFormFields.LABEL]}
												rules={[
													{
														required: true,
														message: t('error.required_field')
													},
													{ min: 1, message: `${t('error.min_length')} 1` }
												]}
											>
												<Input
													placeholder={t('label')}
													onChange={(e) =>
														onChange(
															[...path, `${index}`, EAppFormFields.OPTIONS, `${name}`, EAppFormFields.LABEL],
															e.target.value
														)
													}
												/>
											</Form.Item>
											<Form.Item
												{...restField}
												className='mb-0'
												name={[name, EAppFormFields.VALUE]}
												rules={[
													{
														required: true,
														message: t('error.required_field')
													},
													{ min: 1, message: `${t('error.min_length')} 1` }
												]}
											>
												<Input
													placeholder={t('value')}
													onChange={(e) =>
														onChange(
															[...path, `${index}`, EAppFormFields.OPTIONS, `${name}`, EAppFormFields.VALUE],
															e.target.value
														)
													}
												/>
											</Form.Item>
										</div>
									</div>
								);
							})}
							<Form.Item>
								<Button
									type='dashed'
									className='flex items-center w-full text-gray-light hover:!text-gray-border-dark !border-gray-light hover:!border-gray-border-dark border-2 !bg-white !shadow-none'
									onClick={() => {
										add();
										handleAddSelectOption();
									}}
									icon={<PlusOutlined />}
								/>
							</Form.Item>
						</div>
					);
				}}
			</Form.List>
			<Form.Item
				label={<span>{t('required')}</span>}
				name={[...path, index, EAppFormFields.REQUIRED]}
				initialValue={fieldData[EAppFormFields.REQUIRED]}
			>
				<Switch onChange={(e) => onChange([...path, `${index}`, EAppFormFields.REQUIRED], e)} />
			</Form.Item>
		</div>
	);
};

export const SelectFields = memo(SelectFieldsComponent);
