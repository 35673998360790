import React, { memo } from 'react';
import ReactFlow, { Background, BackgroundVariant, useReactFlow } from 'reactflow';
import { GptCallAction } from './actionNodes/GptCallAction';
import { IdeaAction } from './actionNodes/IdeaAction';
import { InitialNode } from './actionNodes/InitialNode';
import { ApiCallAction } from './actionNodes/ApiCallAction';
import { FormInstance } from 'antd';
import { CustomEdge } from './CustomEdge';
import { JsonataCallAction } from './actionNodes/JsonataCallAction';
import { LlmCallAction } from './actionNodes/LlmCallAction';
import { MailingCallAction } from './actionNodes/MailingCallAction';
import { VaultCallAction } from './actionNodes/VaultCallAction';
import { TaskCallAction } from './actionNodes/TaskCallAction';
import { ApiTemplateCallAction } from './actionNodes/ApiTemplateCallAction';
import { UserInputCallAction } from './actionNodes/UserInputCallAction';
import { IfCallAction } from './actionNodes/IfCallAction';
import { MapCallAction } from './actionNodes/MapCallAction';
import { useAppActionsTree } from '../../lib/useAppActionsTree';
import { AppRunStepAction } from './actionNodes/AppRunStepAction';

const nodeTypes = {
	gpt_call: GptCallAction,
	llm_call: LlmCallAction,
	idea: IdeaAction,
	initial: InitialNode,
	api_call: ApiCallAction,
	api_template_call: ApiTemplateCallAction,
	jsonata_call: JsonataCallAction,
	mailing_call: MailingCallAction,
	vault_call: VaultCallAction,
	task_call: TaskCallAction,
	user_input: UserInputCallAction,
	if_call: IfCallAction,
	map_call: MapCallAction,
	app_run_step: AppRunStepAction
};

interface IAppFlowEditor {
	form: FormInstance;
}

const AppFlowEditorComponent = ({ form }: IAppFlowEditor) => {
	const { screenToFlowPosition } = useReactFlow();
	const { actions, edges, onConnect, onConnectStart, onEdgesChange, onNodesChange, onConnectEnd, onNodeClick } =
		useAppActionsTree(form, screenToFlowPosition);

	const edgeTypes = {
		customedge: CustomEdge
	};

	return (
		<>
			<ReactFlow
				nodes={actions}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				elementsSelectable={true}
				onConnect={onConnect}
				onNodeClick={onNodeClick}
				onConnectStart={onConnectStart}
				onConnectEnd={onConnectEnd}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				multiSelectionKeyCode={null}
				selectionKeyCode={null}
				fitView
				proOptions={{ hideAttribution: true }}
			>
				<Background variant={BackgroundVariant.Dots} gap={6} size={0.5} offset={1} style={{ opacity: '0.15' }} />
			</ReactFlow>
		</>
	);
};

export const AppFlowEditor = memo(AppFlowEditorComponent);
