import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, FormInstance, PopoverProps } from 'antd';
import { EAppFormFields, TUserInputFieldsTypes } from '../../model/types';
import { CustomPopover } from 'shared/ui';
import { PlusOutlined } from 'shared/ui/Icons';
import { useAppDispatch, useAppSelector } from 'shared/model';
import { useTranslate } from 'shared/lib/i18n';
import { selectUserSettings } from '../../model/selectors';
import { createEmptyUserInput } from '../../lib/helpers';
import {
	addOptionToSettingsSelect,
	addUserSetting,
	removeSettingsSelectOption,
	removeUserSetting,
	updateUserSettingsData
} from '../../model/slice';
import { TitleFields } from '../AppFormFields/UserInputCallFields/TitleFields';
import { TextFields } from '../AppFormFields/UserInputCallFields/TextFields';
import { TextareaFields } from '../AppFormFields/UserInputCallFields/TextareaFields';
import { CheckboxFields } from '../AppFormFields/UserInputCallFields/CheckboxFields';
import { SelectFields } from '../AppFormFields/UserInputCallFields/SelectFields';
import { FileFields } from '../AppFormFields/UserInputCallFields/FileFields';
import { methodDebounce } from '../../../../shared/lib';
import { SettingsInputTypeSelector } from './SettingsInputTypeSelector';

interface IAppSettingsFormItems {
	form: FormInstance;
	isActive: boolean;
}

const UserSettingsFormItemsComponent = ({ isActive, form }: IAppSettingsFormItems) => {
	const t = useTranslate();
	const dispatch = useAppDispatch();
	const userSettings = useAppSelector(selectUserSettings);
	const [open, setOpen] = useState(false);
	const handleAddFieldItem = useCallback((fieldType: TUserInputFieldsTypes) => {
		setOpen(false);
		const emptyItem: Record<string, any> = createEmptyUserInput(fieldType);
		dispatch(addUserSetting(emptyItem));
	}, []);

	const handleRemoveFields = useCallback((itemIndex: number) => {
		dispatch(removeUserSetting({ itemIndex }));
	}, []);

	const handleOpenChange: PopoverProps['onOpenChange'] = (newOpen, e) => {
		setOpen(newOpen);
	};

	useEffect(() => {
		form?.resetFields();
	}, [userSettings?.length]);

	const handleFieldChange = (settingIndex: number, path: string[], value: any) => {
		const fullPath = path.join('.');
		dispatch(updateUserSettingsData({ settingIndex, path: fullPath, value }));
	};

	const debouncedHandleFieldChange: (...args: Parameters<typeof handleFieldChange>) => void = useCallback(
		methodDebounce(
			(settingIndex: number, path: string[], value: any) => handleFieldChange(settingIndex, path, value),
			300
		),
		[handleFieldChange]
	);

	const renderedFields = useMemo(() => {
		return userSettings?.map((field: Record<string, any>, index: number) => {
			switch (field.blockType) {
				case 'title':
					return (
						<TitleFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[EAppFormFields.SETTINGS]}
							onChange={(path: string[], value: any) => debouncedHandleFieldChange(index, path, value)}
						/>
					);
				case 'text':
					return (
						<TextFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[EAppFormFields.SETTINGS]}
							onChange={(path: string[], value: any) => debouncedHandleFieldChange(index, path, value)}
						/>
					);
				case 'textarea':
					return (
						<TextareaFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[EAppFormFields.SETTINGS]}
							onChange={(path: string[], value: any) => debouncedHandleFieldChange(index, path, value)}
						/>
					);
				case 'checkbox':
					return (
						<CheckboxFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[EAppFormFields.SETTINGS]}
							onChange={(path: string[], value: any) => debouncedHandleFieldChange(index, path, value)}
						/>
					);
				case 'select':
					return (
						<SelectFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[EAppFormFields.SETTINGS]}
							onChange={(path: string[], value: any) => debouncedHandleFieldChange(index, path, value)}
							handleAddSelectOption={() => {
								dispatch(addOptionToSettingsSelect({ fieldIndex: index, item: { label: '', value: '' } }));
							}}
							handleRemoveOption={(name: number) => {
								dispatch(removeSettingsSelectOption({ fieldIndex: index, optionIndex: name }));
							}}
						/>
					);
				case 'attachedFiles':
					return (
						<FileFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[EAppFormFields.SETTINGS]}
							onChange={(path: string[], value: any) => debouncedHandleFieldChange(index, path, value)}
						/>
					);
				default:
					return null;
			}
		});
	}, [userSettings, userSettings?.length]);

	return (
		<div className={isActive ? '' : 'hidden'}>
			{renderedFields}
			<CustomPopover
				destroyTooltipOnHide={true}
				trigger='click'
				open={open}
				content={<SettingsInputTypeSelector handleTypeSelect={handleAddFieldItem} />}
				onOpenChange={handleOpenChange}
				arrow={false}
			>
				<Button icon={<PlusOutlined />}>{t('add')}</Button>
			</CustomPopover>
		</div>
	);
};

export const UserSettingsFormItems = memo(UserSettingsFormItemsComponent);
