import { memo, useEffect, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { PlusOutlined } from 'shared/ui/Icons';
import { PopoverProps } from 'antd';
import cn from 'classnames';
import { TAppsMenu } from 'entities/apps/api/types';
import { ActionsMenu } from '../../../ActionsMenu';
import { TBlockTypes } from '../../../../model/types';
import { changeActionType } from '../../../../model/slice';
import { useAppDispatch } from 'shared/model';

const InitialNodeComponent = (props: NodeProps) => {
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(true);
	const { selected, data } = props;
	useEffect(() => {
		if (selected) {
			setOpen(true);
		}
	}, [selected]);

	const handleOpenChange: PopoverProps['onOpenChange'] = (newOpen, e) => {
		setOpen(newOpen);
	};

	const selectActionType = async (value: TBlockTypes, defaultValues?: any) => {
		setOpen(false);
		await dispatch(changeActionType({ id: props.id, newType: value, defaultValues }));
	};

	const findMenuItem = (menuItemId: string, menu: TAppsMenu[] | undefined): TAppsMenu | undefined => {
		if (!menu) return;
		for (const menuItem of menu) {
			if (menuItem.id === menuItemId) {
				return menuItem;
			}

			if (menuItem.type === 'group' && menuItem.children) {
				const foundInChildren = findMenuItem(menuItemId, menuItem.children);
				if (foundInChildren) {
					return foundInChildren;
				}
			}
		}

		return undefined;
	};

	return (
		<div
			className={cn(
				'flex items-center justify-center border-2 border-dashed border-font-quaternary rounded p-px bg-white',
				{
					'border-primary-active !border-solid': selected || open
				}
			)}
		>
			{open && (
				<ActionsMenu
					handleCloseMenu={() => setOpen(false)}
					isOpen={open}
					isMapCallItem={data.isMapCallItem}
					selectActionType={selectActionType}
				/>
			)}
			<div
				onClick={(e) => {
					e.preventDefault();
					handleOpenChange(!open);
				}}
				className='min-w-[20px] min-h-[20px] flex items-center justify-center'
			>
				<PlusOutlined className='opacity-25 text-[8px]' />
			</div>
			<Handle type='target' position={Position.Top} className='w-16 !bg-teal-500 h-auto' isConnectable={false} />
		</div>
	);
};

export const InitialNode = memo(InitialNodeComponent);
