import { Node } from 'reactflow';
import { ReactNode } from 'react';
import { FormInstance } from 'antd';

export enum EAppFormFields {
	ID = 'id',
	NAME = 'name',
	SLUG = 'slug',
	TITLE = 'title',
	LABEL = 'label',
	REQUIRED = 'required',
	DEFAULT_VALUE = 'defaultValue',
	OPTIONS = 'options',
	IS_MULTIPLE = 'isMultiple',
	MAX_SIZE = 'maxSize',
	ALLOWED_TYPES = 'allowedTypes',
	NEXT_SLUG = 'nextSlug',
	PLACEHOLDER = 'placeholder',
	BLOCK_NAME = 'blockName',
	URL = 'url',
	URLS = 'urls',
	DESCRIPTION = 'description',
	DESCRIPTION_SMALL = 'descriptionSmall',
	METHOD = 'method',
	BLOCK_TYPE = 'blockType',
	PROMPT = 'prompt',
	TYPE = 'type',
	TYPES = 'types',
	MODEL = 'model',
	AUTONEXT = 'autonext',
	IS_HIDDEN = 'isHidden',
	AUTHOR = 'author',
	TAGS = 'tags',
	IS_PRIVATE = 'isPrivate',
	IS_FAVOURITE = 'isFavourite',
	LOCALE = 'locale',
	ACTIONS = 'actions',
	BODY = 'body',
	JSONATA = 'jsonata',
	IS_XML = 'isXml',
	HEADERS = 'headers',
	FIELDS = 'fields',
	VALUE = 'value',
	EXTRACT_IMAGES = 'extract_images',
	MAX_TOKENS = 'max_tokens',
	CONTENT_CONVERSATION_TYPE = 'contentConversionType',
	ASSISTANT = 'assistant',
	SUCCESS = 'success',
	FAIL = 'fail',
	CONDITION = 'condition',
	PASSED_VALUE = 'passedValue',
	OPERATOR = 'operator',
	ITEM_SLUG = 'itemSlug',
	CALLBACKS = 'callbacks',
	TEMPLATE = 'template',
	EXPRESSION = 'expression',
	TEMPERATURE = 'temperature',
	MESSAGE = 'message',
	EMAILS = 'emails',
	EMOJI = 'emoji',
	VAULT = 'vault',
	PLUGIN = 'plugin',
	TASK = 'task',
	PARAMS = 'params',
	APP_ID = 'appId',
	IDEA = 'idea',
	SETTINGS = 'settings',
	RAG_SETTINGS = 'ragSettings',
	IS_ENABLE = 'isEnable',
	EMBEDDING_QUERY_STRING = 'embeddingQueryString',
	CONTEXT_SIZE_IN_CHARS = 'contextSizeInChars'
}

export enum ENodeHandleVariants {
	SUCCESS = 'success',
	FAIL = 'fail',
	ITEM_SLUG = 'itemSlug',
	SOURCE_HANDLE = 'sourceHandle'
}

export type TActionData = {
	[EAppFormFields.SLUG]: string;
	[EAppFormFields.ITEM_SLUG]?: string;
	[EAppFormFields.BLOCK_TYPE]: string;
	[EAppFormFields.ID]?: string;
	[EAppFormFields.NAME]?: string;
	[EAppFormFields.PLACEHOLDER]?: string;
	[EAppFormFields.BLOCK_NAME]?: string;
	[EAppFormFields.URL]?: string;
	[EAppFormFields.DESCRIPTION]?: string;
	[EAppFormFields.METHOD]?: string;
	[EAppFormFields.NEXT_SLUG]?: string;
	[EAppFormFields.IS_HIDDEN]?: boolean;
	[EAppFormFields.TYPE]?: TBlockTypes;
	[EAppFormFields.TYPES]?: ['telegram', 'email'];
};

export type TEditAppForm = {
	[EAppFormFields.AUTHOR]: string;
	[EAppFormFields.NAME]: string;
	[EAppFormFields.PLACEHOLDER]?: string;
	[EAppFormFields.DESCRIPTION]?: string;
	[EAppFormFields.TAGS]: string[];
	[EAppFormFields.IS_PRIVATE]?: boolean;
	[EAppFormFields.LOCALE]: string[];
	[EAppFormFields.ACTIONS]: TActionData[];
};

export type TBlockTypes =
	| 'api_call'
	| 'api_template_call'
	| 'gpt_call'
	| 'initial'
	| 'idea'
	| 'jsonata_call'
	| 'llm_call'
	| 'mailing_call'
	| 'vault_call'
	| 'user_input'
	| 'if_call'
	| 'map_call'
	| 'app_run_step'
	| 'task_call';

export type TErrorFields = {
	name: (string | number)[];
	errors: string[];
};

export type TActionNode = Node<Record<string, any>>;

export type TUserInputFieldsTypes = 'title' | 'checkbox' | 'text' | 'textarea' | 'select' | 'attachedFiles';

export type TActionConfigItem = {
	name: string;
	inputType: 'radio' | 'checkbox' | 'text' | 'textArea' | 'select' | 'multiselect' | 'switch';
	rules: Record<string, any>[];
	isHidden?: boolean;
	label?: string;
	options?: { label: string; value: string | number }[];
};

export type TAppFormFields = {
	action: TActionNode;
	title?: string;
	actionsSlot?: ReactNode;
	actionIndex: number;
	onChange: (path: string[], value: any) => void;
	form?: FormInstance;
	path: string[];
};

export type TUserInputItemFields = Pick<TAppFormFields, 'onChange' | 'path'> & {
	fieldData: Record<string, any>;
	index: number;
	handleRemoveFields: (index: number) => void;
};

export type TEditorTabs = 'settings' | 'steps' | 'userSettings' | 'data';

export type TSettingsTemplateForm = {
	app: string;
	name: string;
	id?: string;
	values: Record<string, any>;
};
