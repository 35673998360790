import { EAppFormFields, ENodeHandleVariants, TActionNode, TUserInputFieldsTypes } from '../model/types';
import { Edge, MarkerType } from 'reactflow';
import { STYLES } from '../../../shared';

export const createEmptyUserInput = (fieldType: TUserInputFieldsTypes): Record<string, any> => {
	const emptyItem: Record<string, any> = { [EAppFormFields.BLOCK_TYPE]: fieldType };
	switch (fieldType) {
		case 'title':
			emptyItem[EAppFormFields.TITLE] = '';
			break;
		case 'text':
			emptyItem[EAppFormFields.NAME] = '';
			emptyItem[EAppFormFields.LABEL] = '';
			emptyItem[EAppFormFields.REQUIRED] = '';
			emptyItem[EAppFormFields.DEFAULT_VALUE] = '';
			break;
		case 'textarea':
			emptyItem[EAppFormFields.NAME] = '';
			emptyItem[EAppFormFields.LABEL] = '';
			emptyItem[EAppFormFields.REQUIRED] = '';
			emptyItem[EAppFormFields.DEFAULT_VALUE] = '';
			break;
		case 'checkbox':
			emptyItem[EAppFormFields.NAME] = '';
			emptyItem[EAppFormFields.LABEL] = '';
			emptyItem[EAppFormFields.REQUIRED] = '';
			break;
		case 'select':
			emptyItem[EAppFormFields.NAME] = '';
			emptyItem[EAppFormFields.LABEL] = '';
			emptyItem[EAppFormFields.REQUIRED] = '';
			emptyItem[EAppFormFields.OPTIONS] = [];
			break;
		case 'attachedFiles':
			emptyItem[EAppFormFields.NAME] = '';
			emptyItem[EAppFormFields.LABEL] = '';
			emptyItem[EAppFormFields.REQUIRED] = '';
			emptyItem[EAppFormFields.IS_MULTIPLE] = '';
			emptyItem[EAppFormFields.MAX_SIZE] = '';
			emptyItem[EAppFormFields.ALLOWED_TYPES] = [];
			break;
	}
	return emptyItem;
};

interface ICustomEdge {
	id: string;
	source: string;
	target: string;
	sourceHandle?: string;
}
export const generateCustomEdge = ({ id, source, target, sourceHandle }: ICustomEdge): Edge => {
	return {
		id,
		source,
		target,
		sourceHandle,
		type: 'customedge',
		className: '!cursor-auto',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			color: STYLES.colors.gray_light
		},
		style: {
			stroke: STYLES.colors.gray_light,
			strokeWidth: 2
		}
	};
};

export const generateCustomEdgesList = (actions: TActionNode[]): Edge[] => {
	const edges: Edge[] = [];
	actions.forEach((action) => {
		if (typeof action.data.nextSlug === 'object' && action.data.blockType === 'if_call') {
			if (action.data.nextSlug[ENodeHandleVariants.SUCCESS]) {
				edges.push(
					generateCustomEdge({
						id: `${action.id}->${action.data.nextSlug[ENodeHandleVariants.SUCCESS]}`,
						source: action.id,
						target: action.data.nextSlug[ENodeHandleVariants.SUCCESS],
						sourceHandle: ENodeHandleVariants.SUCCESS
					})
				);
			}
			if (action.data.nextSlug[ENodeHandleVariants.FAIL]) {
				edges.push(
					generateCustomEdge({
						id: `${action.id}->${action.data.nextSlug[ENodeHandleVariants.FAIL]}`,
						source: action.id,
						target: action.data.nextSlug[ENodeHandleVariants.FAIL],
						sourceHandle: ENodeHandleVariants.FAIL
					})
				);
			}
		} else {
			if (action.data.nextSlug) {
				edges.push(
					generateCustomEdge({
						id: `${action.id}->${action.data.nextSlug}`,
						source: action.id,
						target: action.data.nextSlug
					})
				);
			}
			if (action.data.itemSlug) {
				edges.push(
					generateCustomEdge({
						id: `${action.id}->${action.data.itemSlug}`,
						source: action.id,
						target: action.data.itemSlug,
						sourceHandle: ENodeHandleVariants.ITEM_SLUG
					})
				);
			}
		}
	});

	return edges;
};
