import React, { memo } from 'react';
import { EAppFormFields, TAppFormFields } from '../../../model/types';
import { Divider, Form, Input, InputNumber, Switch } from 'antd';
import { LabelWithInfo } from 'shared/ui/LabelWithInfo';
import { validateNotEmpty } from 'shared/lib';
import { useTranslate } from 'shared/lib/i18n';
import { VaultSelect } from '../VaultSelect';
import { VaultActionIcon } from 'shared/ui/Icons';

const VaultCallFieldsComponent = ({ action, title, actionsSlot, onChange, path }: TAppFormFields) => {
	const t = useTranslate();

	return (
		<div className={action.selected ? '' : 'hidden'}>
			<div className='flex justify-between text-base font-medium mb-5'>
				<div className='flex items-center gap-x-2.5'>
					<div className='bg-fill-tertiary rounded w-[20px] min-w-[20px] min-h-[20px] flex items-center justify-center'>
						<VaultActionIcon />
					</div>
					{title}
				</div>
				{actionsSlot}
			</div>
			<Form.Item
				label={EAppFormFields.NEXT_SLUG}
				name={[...path, EAppFormFields.NEXT_SLUG]}
				initialValue={action.data[EAppFormFields.NEXT_SLUG]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.BLOCK_TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
				name={[...path, EAppFormFields.BLOCK_NAME]}
				initialValue={action.data[EAppFormFields.BLOCK_NAME]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('dynamic_name')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('dynamic_name.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.SLUG]}
				initialValue={action.data[EAppFormFields.SLUG]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('description')}</span>}
				name={[...path, EAppFormFields.DESCRIPTION]}
				initialValue={action.data[EAppFormFields.DESCRIPTION]}
				rules={[
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 2, maxRows: 4 }}
					onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
				/>
			</Form.Item>
			<VaultSelect
				name={[...path, EAppFormFields.VAULT]}
				initialValue={action.data[EAppFormFields.VAULT]}
				onChange={(value) => onChange([...path, EAppFormFields.VAULT], value)}
			/>
			<Form.Item name={[...path, EAppFormFields.AUTONEXT]} initialValue={true} hidden={true}>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
			</Form.Item>
			<Form.Item name={[...path, EAppFormFields.IS_HIDDEN]} initialValue={false} hidden={true}>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
			</Form.Item>
			<Divider style={{ marginBottom: '8px' }} />
			<Form.Item
				label={<span>{`${t('turn_on')} RAG`}</span>}
				name={[...path, EAppFormFields.RAG_SETTINGS, EAppFormFields.IS_ENABLE]}
				initialValue={action.data[EAppFormFields.RAG_SETTINGS]?.[EAppFormFields.IS_ENABLE]}
				className='mb-2'
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.RAG_SETTINGS, EAppFormFields.IS_ENABLE], e)} />
			</Form.Item>
			{!!action.data[EAppFormFields.RAG_SETTINGS]?.[EAppFormFields.IS_ENABLE] && (
				<>
					<Form.Item
						label={
							<span>
								{t('data_selection_string')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						name={[...path, EAppFormFields.RAG_SETTINGS, EAppFormFields.EMBEDDING_QUERY_STRING]}
						initialValue={action.data[EAppFormFields.RAG_SETTINGS]?.[EAppFormFields.EMBEDDING_QUERY_STRING]}
						rules={[
							{
								required: !!action.data[EAppFormFields.RAG_SETTINGS]?.[EAppFormFields.IS_ENABLE],
								message: t('error.required_field')
							},

							{ min: 2, message: `${t('error.min_length')} 2` },
							{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 4 }}
							onChange={(e) =>
								onChange([...path, EAppFormFields.RAG_SETTINGS, EAppFormFields.EMBEDDING_QUERY_STRING], e.target.value)
							}
						/>
					</Form.Item>
					<Form.Item
						label={<span>{t('data_selection_in_characters')}</span>}
						name={[...path, EAppFormFields.RAG_SETTINGS, EAppFormFields.CONTEXT_SIZE_IN_CHARS]}
						initialValue={action.data[EAppFormFields.RAG_SETTINGS]?.[EAppFormFields.CONTEXT_SIZE_IN_CHARS]}
						rules={[]}
					>
						<InputNumber
							min='0'
							onChange={(value) =>
								onChange([...path, EAppFormFields.RAG_SETTINGS, EAppFormFields.CONTEXT_SIZE_IN_CHARS], value)
							}
							className='w-full'
						/>
					</Form.Item>
				</>
			)}
		</div>
	);
};

export const VaultCallFields = memo(VaultCallFieldsComponent);
