import React, { memo } from 'react';
import { EAppFormFields, TAppFormFields } from '../../../model/types';
import { Form, Input, Switch } from 'antd';
import { LabelWithInfo } from 'shared/ui/LabelWithInfo';
import { validateNotEmpty } from 'shared/lib';
import { useTranslate } from 'shared/lib/i18n';
import { AppSelect } from '../AppSelect';
import { PlaySquareOutlined } from 'shared/ui/Icons';

const AppRunStepFieldsComponent = ({ action, title, actionsSlot, onChange, path }: TAppFormFields) => {
	const t = useTranslate();

	return (
		<div className={action.selected ? '' : 'hidden'}>
			<div className='flex justify-between text-base font-medium mb-5'>
				<div className='flex items-center gap-x-2.5'>
					<div className='bg-fill-tertiary rounded w-[20px] min-w-[20px] min-h-[20px] flex items-center justify-center'>
						<PlaySquareOutlined className='text-primary-active' />
					</div>
					{title}
				</div>
				{actionsSlot}
			</div>
			<Form.Item
				label={EAppFormFields.NEXT_SLUG}
				name={[...path, EAppFormFields.NEXT_SLUG]}
				initialValue={action.data[EAppFormFields.NEXT_SLUG]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.BLOCK_TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
				name={[...path, EAppFormFields.BLOCK_NAME]}
				initialValue={action.data[EAppFormFields.BLOCK_NAME]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('dynamic_name')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('dynamic_name.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.SLUG]}
				initialValue={action.data[EAppFormFields.SLUG]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('description')}</span>}
				name={[...path, EAppFormFields.DESCRIPTION]}
				initialValue={action.data[EAppFormFields.DESCRIPTION]}
				rules={[
					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 2, maxRows: 4 }}
					onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
				/>
			</Form.Item>
			<AppSelect
				name={[...path, EAppFormFields.APP_ID]}
				initialValue={action.data[EAppFormFields.APP_ID]}
				onChange={(value) => onChange([...path, EAppFormFields.APP_ID], value)}
			/>
			<Form.Item
				label={
					<span>
						{t('idea')}
						<span className='ml-1 text-error'>*</span>
					</span>
				}
				name={[...path, EAppFormFields.IDEA]}
				initialValue={action.data[EAppFormFields.IDEA]}
				className='mb-2'
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},

					{ min: 2, message: `${t('error.min_length')} 2` },
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') }
				]}
			>
				<Input.TextArea
					autoSize={{ minRows: 4 }}
					onChange={(e) => onChange([...path, EAppFormFields.IDEA], e.target.value)}
				/>
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={<span>{t('visibility')}</span>}
						tooltipText={t('visibility.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.IS_HIDDEN]}
				initialValue={action.data[EAppFormFields.IS_HIDDEN]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo label={<span>{t('autonext')}</span>} tooltipText={t('autonext.description')}></LabelWithInfo>
				}
				name={[...path, EAppFormFields.AUTONEXT]}
				initialValue={action.data[EAppFormFields.AUTONEXT]}
				rules={[]}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
			</Form.Item>
		</div>
	);
};

export const AppRunStepFields = memo(AppRunStepFieldsComponent);
