import { memo } from 'react';
import { Form, Select } from 'antd';
import { useTranslate } from 'shared/lib/i18n';
import { useGetVaultsQuery } from 'entities/vaults';

interface IVaultSelect {
	name: string[];
	onChange: (value: any) => void;
	initialValue?: any;
}

const VaultSelectComponent = ({ name, onChange, initialValue }: IVaultSelect) => {
	const t = useTranslate();
	// TODO remove hardcoded vaults per page when add Select with infinity scroll
	const { vaults } = useGetVaultsQuery(
		{
			page: 1,
			vaultsPerPage: 1000
		},
		{
			selectFromResult: ({ data, isLoading }) => ({
				vaults: data?.docs.map((vault) => ({ value: vault.id, label: vault.name })) ?? [],
				isLoading
			}),
			refetchOnMountOrArgChange: true
		}
	);

	return (
		<Form.Item
			label={
				<span>
					{t('vault')}
					<span className='ml-1 text-error'>*</span>
				</span>
			}
			name={name}
			initialValue={initialValue}
			rules={[{ required: true, message: t('error.required_field') }]}
		>
			<Select onChange={onChange} options={vaults} />
		</Form.Item>
	);
};

export const VaultSelect = memo(VaultSelectComponent);
