import { memo, useMemo, useState } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, MarkerType } from 'reactflow';
import { PlusOutlined } from 'shared/ui/Icons';
import { TBlockTypes } from '../../../model/types';
import {
	setActions,
	setEdges,
	setInitialRegenerateIndex,
	setIsActionListChanged,
	updateActionData
} from '../../../model/slice';
import { useAppDispatch, useAppSelector } from 'shared/model';
import { selectActions, selectEdges } from '../../../model/selectors';
import { generateId } from 'shared/lib';
import { STYLES } from 'shared';
import cn from 'classnames';
import { ActionsMenu } from '../../ActionsMenu';

const CustomEdgeComponent = ({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	markerEnd,
	source,
	target
}: EdgeProps) => {
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const actions = useAppSelector(selectActions);
	const edges = useAppSelector(selectEdges);

	const targetAction = useMemo(() => actions.find((action) => action.id === target), [actions]);
	const sourceAction = useMemo(() => actions.find((action) => action.id === source), [actions]);
	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition
	});

	const selectActionType = (value: TBlockTypes, defaultValues?: any) => {
		setOpen(false);
		if (!!sourceAction && !!targetAction) {
			const sourceIndex = actions?.findIndex((action) => action.id === source);
			const newActionId = `${generateId().substring(0, 5)}`;
			const newActions = actions.map((action) => ({ ...action, selected: false }));
			const actionData = defaultValues ?? {
				...(value === 'gpt_call' ? { contentConversionType: 'notConverted', model: 'gpt-4o', temperature: '0.5' } : {}),
				...(value === 'llm_call' ? { contentConversionType: 'notConverted', temperature: '0.7' } : {}),
				...(value === 'jsonata_call' ? { expression: '$' } : {}),
				...(value === 'mailing_call' ? { types: { telegram: true, email: true } } : {}),
				...(value === 'api_call' ? { headers: [] } : {}),
				...(value === 'user_input' ? { fields: [] } : {})
			};
			newActions.splice(sourceIndex + 1, 0, {
				id: newActionId,
				type: value,
				position: {
					x: labelX,
					y: labelY
				},
				selected: true,
				data: {
					slug: newActionId,
					type: 'static_text',
					blockType: value,
					isHidden: true,
					...actionData
				}
			});
			dispatch(setActions(newActions));
			const updatedEdges = edges
				?.filter((edge) => edge.id !== id)
				.concat([
					{
						id: `${source}->${newActionId}`,
						source: source,
						target: newActionId,
						type: 'customedge',
						className: '!cursor-auto',
						markerEnd: {
							type: MarkerType.ArrowClosed,
							color: STYLES.colors.gray_light
						},
						style: {
							stroke: STYLES.colors.gray_light,
							strokeWidth: 2
						}
					},
					{
						id: `${newActionId}->${target}`,
						source: newActionId,
						target: target,
						type: 'customedge',
						className: '!cursor-auto',
						markerEnd: {
							type: MarkerType.ArrowClosed,
							color: STYLES.colors.gray_light
						},
						style: {
							stroke: STYLES.colors.gray_light,
							strokeWidth: 2
						}
					}
				]);
			dispatch(setEdges(updatedEdges));
			dispatch(
				updateActionData({ actionId: source, path: ['actions', sourceIndex, 'nextSlug'].join('.'), value: newActionId })
			);
			dispatch(
				updateActionData({
					actionId: newActionId,
					path: ['actions', sourceIndex + 1, 'nextSlug'].join('.'),
					value: target
				})
			);
			dispatch(setIsActionListChanged(true));
			dispatch(setInitialRegenerateIndex(1));
		}
	};

	return (
		<>
			<BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
			{targetAction?.type !== 'initial' && !targetAction?.data.isMapCallItem && (
				<EdgeLabelRenderer>
					<div
						style={{
							position: 'absolute',
							transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
							pointerEvents: 'all'
						}}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
					>
						{open && (
							<ActionsMenu handleCloseMenu={() => setOpen(false)} isOpen={open} selectActionType={selectActionType} />
						)}
						<div
							className={cn(
								'w-[8px] h-[8px] flex items-center justify-center rounded-full p-px hover:bg-white cursor-pointer transition-all duration-100 overflow-hidden',
								'hover:w-[20px] hover:h-[20px] hover:border hover:border-dashed hover:rounded',
								{
									'border-gray-light bg-gray-light': style?.stroke === STYLES.colors.gray_light,
									'border-primary bg-primary': style?.stroke === STYLES.colors.primary,
									'!w-[20px] !h-[20px] !border !border-dashed !rounded !bg-white': open
								}
							)}
							onClick={() => setOpen(true)}
						>
							<PlusOutlined
								className={cn('text-[8px]', {
									'text-gray-light': style?.stroke === STYLES.colors.gray_light,
									'text-primary': style?.stroke === STYLES.colors.primary
								})}
							/>
						</div>
					</div>
				</EdgeLabelRenderer>
			)}
		</>
	);
};
export const CustomEdge = memo(CustomEdgeComponent);
