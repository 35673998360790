import React, { memo, useEffect, useMemo, useState } from 'react';
import { ArrowRightOutlined, CloseOutlined } from 'shared/ui/Icons';
import { TBlockTypes } from '../../model/types';
import { Button, Drawer, Typography } from 'antd';
import { TAppsMenu } from 'entities/apps/api/types';
import { useGetAppsMenuQuery } from 'entities/apps';
import cx from './ActionsMenu.module.css';
import cn from 'classnames';
import { useDebounce } from 'shared/lib/useDebounce';
import { EmptyList, Search } from 'shared/ui';
import { useTranslate } from 'shared/lib/i18n';

interface IActionsMenu {
	handleCloseMenu: () => void;
	selectActionType: (value: TBlockTypes, defaultValues?: any) => void;
	isOpen: boolean;
	isMapCallItem?: boolean;
}

const ActionsMenuComponent = ({ handleCloseMenu, isOpen, isMapCallItem, selectActionType }: IActionsMenu) => {
	const t = useTranslate();
	const [groupChain, setGroupСhain] = useState<TAppsMenu[]>([]);
	const [currentGroup, setCurrentGroup] = useState<TAppsMenu | undefined>();
	const [searchQuery, setSearchQuery] = useState('');
	const [searchResults, setSearchResults] = useState<Array<{ foundApp: TAppsMenu; parents: TAppsMenu[] }>>([]);

	const debouncedSearchQuery = useDebounce(searchQuery, 300);

	const { data: menuData } = useGetAppsMenuQuery();

	const moveToGroup = (groupId: string) => {
		const targetGroupIndex = groupChain.findIndex((group) => group.id === groupId);
		if (targetGroupIndex !== -1) {
			setCurrentGroup(groupChain[targetGroupIndex]);
			const newGroupChain = [...groupChain].splice(0, targetGroupIndex + 1);
			setGroupСhain(newGroupChain);
			setSearchQuery('');
			setSearchResults([]);
		}
	};

	const findAllAppsByName = (
		name: string,
		appsMenu: TAppsMenu[],
		parents: TAppsMenu[] = []
	): Array<{ foundApp: TAppsMenu; parents: TAppsMenu[] }> => {
		const results: Array<{ foundApp: TAppsMenu; parents: TAppsMenu[] }> = [];

		for (const app of appsMenu) {
			if (app.name.toLowerCase().includes(name.toLowerCase())) {
				results.push({ foundApp: app, parents });
			}

			if (app.type === 'group' && app.children) {
				const childResults = findAllAppsByName(name, app.children, [...parents, app]);
				results.push(...childResults);
			}
		}

		return results;
	};

	useEffect(() => {
		if (debouncedSearchQuery && menuData?.menu) {
			const results = findAllAppsByName(debouncedSearchQuery, menuData?.menu);
			setSearchResults(results);
		} else {
			setSearchResults([]);
		}
	}, [debouncedSearchQuery, menuData?.menu]);

	const renderItemList = useMemo(() => {
		let targetItems: TAppsMenu[] = [];
		if (searchQuery && searchResults) {
			targetItems = searchResults.map((searchItem) => searchItem.foundApp);
		} else if (currentGroup?.type === 'group') {
			targetItems = currentGroup.children;
		} else if (menuData?.menu) {
			targetItems = menuData?.menu;
		}
		if (searchQuery && !searchResults.length) {
			return (
				<div className='flex flex-col items-center w-full text-center mt-[100px]'>
					<EmptyList message={t('empty_search_message')} />
				</div>
			);
		}

		return targetItems.map((menuItem, index) => {
			return menuItem.type === 'group' ? (
				<div
					key={`${menuItem.id}-${index}-${menuItem.name}`}
					onClick={() => {
						const parents = searchResults[index]?.parents;
						setCurrentGroup(menuItem);
						if (searchQuery && parents) {
							setGroupСhain([...parents, menuItem]);
							setSearchQuery('');
							setSearchResults([]);
						} else {
							setGroupСhain((prev) => [...prev, menuItem]);
						}
					}}
					className={cx.groupItem}
				>
					<div className='rounded min-w-[60px] min-h-[60px] max-w-[60px] max-h-[60px] flex items-center justify-center'>
						<img src={menuItem.iconUrl} alt='' className='max-w-full' />
					</div>
					<div className='max-w-full flex flex-wrap flex-col justify-start items-start'>
						<span className='text-base font-medium'>{menuItem.name}</span>
						<Typography.Paragraph
							rootClassName='text-sm !m-0 max-w-full'
							ellipsis={{
								rows: 2,
								expanded: false
							}}
						>
							{menuItem.description}
						</Typography.Paragraph>
					</div>
					<ArrowRightOutlined className={cx.arrow} />
				</div>
			) : (
				<div
					key={`${menuItem.id}-${index}-${menuItem.name}`}
					onClick={() => {
						selectActionType(menuItem.action.blockType as unknown as TBlockTypes, menuItem.action);
					}}
					className={cn(cx.menuAction, {
						hidden:
							isMapCallItem &&
							['vault_call', 'task_call', 'condition', 'map_call'].includes(
								menuItem.action.blockType as unknown as TBlockTypes
							)
					})}
				>
					<div className='rounded min-w-[60px] min-h-[60px] max-w-[60px] max-h-[60px] flex items-center justify-center'>
						<img src={menuItem.iconUrl} alt='' className='max-w-full' />
					</div>
					<div className='max-w-full flex flex-wrap flex-col justify-start items-start'>
						<span className='text-base font-medium'>{menuItem.name}</span>
						<Typography.Paragraph
							rootClassName='text-sm !m-0 max-w-full'
							ellipsis={{
								rows: 2,
								expanded: false
							}}
						>
							{menuItem.description}
						</Typography.Paragraph>
					</div>
				</div>
			);
		});
	}, [menuData?.menu, currentGroup, searchQuery, searchResults, t]);

	return (
		<Drawer
			width={868}
			destroyOnClose={true}
			closeIcon={<CloseOutlined className='opacity-[0.88]' />}
			classNames={{
				header: 'bg-white flex max-w-max !border-none',
				body: '!p-5 border-solid border-x-0 border-b-0 border-t border-gray-border'
			}}
			onClose={() => handleCloseMenu()}
			open={isOpen}
			extra={
				<div className='flex items-center w-full'>
					<Button
						type='link'
						onClick={() => {
							setCurrentGroup(undefined);
							setGroupСhain([]);
							setSearchQuery('');
							setSearchResults([]);
						}}
						className='p-1 text-description-font hover:!text-dark'
					>
						Следующий шаг
					</Button>
					{groupChain.map((group, index) => (
						<>
							<span className='text-description-font'>/</span>
							<Button
								type='link'
								key={group.id}
								onClick={() => moveToGroup(group.id)}
								className={`p-1 text-description-font hover:!text-dark ${
									index === groupChain.length - 1 ? '!text-dark pointer-events-none' : ''
								}`}
							>
								{group.name}
							</Button>
						</>
					))}
				</div>
			}
		>
			<>
				<div>
					<Search
						placeholder={t('search')}
						onChange={(e) => setSearchQuery(e.target.value)}
						className='min-w-[300px] my-other-step mb-6'
						value={searchQuery}
						allowClear={true}
					/>
				</div>
				<div className='flex flex-wrap items-start gap-3'>{renderItemList}</div>
			</>
		</Drawer>
	);
};

export const ActionsMenu = memo(ActionsMenuComponent);
