import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { EAppFormFields, TAppFormFields, TUserInputFieldsTypes } from '../../../model/types';
import { Button, Form, Input, PopoverProps, Switch, Typography } from 'antd';
import { LabelWithInfo } from 'shared/ui/LabelWithInfo';
import { PlusOutlined, CodeOutlined } from 'shared/ui/Icons';
import { useTranslate } from 'shared/lib/i18n';
import { useAppDispatch } from 'shared/model';
import {
	addEmptyItemToArray,
	addOptionToUserSelect,
	removeArrayItem,
	removeUserSelectOption
} from '../../../model/slice';
import { CustomPopover } from 'shared/ui';
import { TitleFields } from './TitleFields';
import { TextFields } from './TextFields';
import { TextareaFields } from './TextareaFields';
import { CheckboxFields } from './CheckboxFields';
import { SelectFields } from './SelectFields';
import { InputTypeSelector } from './InputTypeSelector';
import { createEmptyUserInput } from '../../../lib/helpers';
import { FileFields } from './FileFields';

const UserInputCallFieldsComponent = ({ action, title, actionsSlot, onChange, path, form }: TAppFormFields) => {
	const t = useTranslate();
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const handleAddFieldItem = useCallback(
		(fieldType: TUserInputFieldsTypes) => {
			setOpen(false);
			const emptyItem: Record<string, any> = createEmptyUserInput(fieldType);
			dispatch(addEmptyItemToArray({ actionId: action.id!, key: 'fields', item: emptyItem }));
		},
		[action.id]
	);

	const handleRemoveFields = useCallback(
		(name: number) => {
			dispatch(removeArrayItem({ actionId: action.id!, key: 'fields', itemIndex: name }));
		},
		[action.id]
	);

	const handleOpenChange: PopoverProps['onOpenChange'] = (newOpen, e) => {
		setOpen(newOpen);
	};

	useEffect(() => {
		form?.resetFields();
	}, [action.data.fields.length]);

	const renderedFields = useMemo(() => {
		return action.data.fields.map((field: Record<string, any>, index: number) => {
			switch (field.blockType) {
				case 'title':
					return (
						<TitleFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[...path, EAppFormFields.FIELDS]}
							onChange={onChange}
						/>
					);
				case 'text':
					return (
						<TextFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[...path, EAppFormFields.FIELDS]}
							onChange={onChange}
						/>
					);
				case 'textarea':
					return (
						<TextareaFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[...path, EAppFormFields.FIELDS]}
							onChange={onChange}
						/>
					);
				case 'checkbox':
					return (
						<CheckboxFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[...path, EAppFormFields.FIELDS]}
							onChange={onChange}
						/>
					);
				case 'select':
					return (
						<SelectFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[...path, EAppFormFields.FIELDS]}
							onChange={onChange}
							handleAddSelectOption={() => {
								dispatch(
									addOptionToUserSelect({ actionId: action.id!, fieldIndex: index, item: { label: '', value: '' } })
								);
							}}
							handleRemoveOption={(name: number) => {
								dispatch(removeUserSelectOption({ actionId: action.id!, fieldIndex: index, optionIndex: name }));
							}}
						/>
					);
				case 'attachedFiles':
					return (
						<FileFields
							key={`${field}_${index}`}
							fieldData={field}
							handleRemoveFields={handleRemoveFields}
							index={index}
							path={[...path, EAppFormFields.FIELDS]}
							onChange={onChange}
						/>
					);
				default:
					return null;
			}
		});
	}, [action.data.fields]);

	return (
		<div className={action.selected ? '' : 'hidden'}>
			<div className='flex justify-between text-base font-medium mb-5'>
				<div className='flex items-center gap-x-2.5'>
					<div className='bg-fill-tertiary rounded w-[20px] min-w-[20px] min-h-[20px] flex items-center justify-center'>
						<CodeOutlined className='text-yellow-500' />
					</div>
					{title}
				</div>
				{actionsSlot}
			</div>
			<Form.Item
				label={EAppFormFields.NEXT_SLUG}
				name={[...path, EAppFormFields.NEXT_SLUG]}
				initialValue={action.data[EAppFormFields.NEXT_SLUG]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.NEXT_SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<span>
						{EAppFormFields.BLOCK_TYPE}
						<span className='ml-1 text-error'>*</span>
					</span>
				}
				name={[...path, EAppFormFields.BLOCK_TYPE]}
				initialValue={action.data[EAppFormFields.BLOCK_TYPE]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					}
				]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<LabelWithInfo label={<span>{t('step_name')}</span>} tooltipText={t('step_name.description')} />}
				name={[...path, EAppFormFields.BLOCK_NAME]}
				initialValue={action.data[EAppFormFields.BLOCK_NAME]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input onChange={(e) => onChange([...path, EAppFormFields.BLOCK_NAME], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={
							<span>
								{t('dynamic_name')}
								<span className='ml-1 text-error'>*</span>
							</span>
						}
						tooltipText={t('dynamic_name.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.SLUG]}
				initialValue={action.data[EAppFormFields.SLUG]}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onBlur={(e) => onChange([...path, EAppFormFields.SLUG], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={<span>{t('description')}</span>}
				name={[...path, EAppFormFields.DESCRIPTION]}
				initialValue={action.data[EAppFormFields.DESCRIPTION]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
			>
				<Input.TextArea
					autoSize={{ minRows: 2, maxRows: 4 }}
					onChange={(e) => onChange([...path, EAppFormFields.DESCRIPTION], e.target.value)}
				/>
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo
						label={<span>{t('visibility')}</span>}
						tooltipText={t('visibility.description')}
					></LabelWithInfo>
				}
				name={[...path, EAppFormFields.IS_HIDDEN]}
				initialValue={false}
				hidden={true}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.IS_HIDDEN], e)} />
			</Form.Item>
			<Form.Item
				label={
					<LabelWithInfo label={<span>{t('autonext')}</span>} tooltipText={t('autonext.description')}></LabelWithInfo>
				}
				name={[...path, EAppFormFields.AUTONEXT]}
				initialValue={true}
				hidden={true}
			>
				<Switch onChange={(e) => onChange([...path, EAppFormFields.AUTONEXT], e)} />
			</Form.Item>
			<Typography.Paragraph className='font-medium !mb-0 text-black'>{t('elements')}</Typography.Paragraph>
			{renderedFields}
			<CustomPopover
				destroyTooltipOnHide={true}
				trigger='click'
				open={open}
				content={<InputTypeSelector handleTypeSelect={handleAddFieldItem} />}
				onOpenChange={handleOpenChange}
				arrow={false}
			>
				<Button icon={<PlusOutlined />}>{t('add')}</Button>
			</CustomPopover>
		</div>
	);
};

export const UserInputCallFields = memo(UserInputCallFieldsComponent);
