import React, { memo } from 'react';
import cx from '../Message.module.css';

import { TMessage } from '../../../model/types';
import { StapleIcon as Staple } from 'shared/ui/Icons';

import cn from 'classnames';

interface IContextMessage {
	message: TMessage;
	disabled?: boolean;
}

const ContextMessageComponent = ({ message, disabled }: IContextMessage) => {
	return (
		<div className={cx.root}>
			<div className='w-full max-w-[800px] flex relative justify-end'>
				<div
					className={cn(`${cx.inner} !rounded-br-none`, {
						'pointer-events-none opacity-60': disabled
					})}
				>
					<Staple className='text-transparent text-2xl stroke-black' />

					{message.choices.map((contentItem) => (
						<div className='ml-2 flex flex-col w-full pl-0' key={contentItem.id}>
							{contentItem.content}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export const ContextMessage = memo(ContextMessageComponent);
