import React, { memo } from 'react';
import { Divider, Form, Input } from 'antd';
import { EAppFormFields, TUserInputItemFields } from '../../../../model/types';
import { DeleteOutlined } from 'shared/ui/Icons';
import { validateNotEmpty } from 'shared/lib';
import { useTranslate } from 'shared/lib/i18n';

const TitleFieldsComponent = ({ fieldData, onChange, path, handleRemoveFields, index }: TUserInputItemFields) => {
	const t = useTranslate();
	return (
		<div>
			<Divider style={{ margin: '8px 0' }} />
			<div className='flex w-full font-bold gap-x-1.5 mb-2 mt-4'>
				<div>{t('input.title')}</div>
				<DeleteOutlined
					className='text-error'
					onClick={() => {
						handleRemoveFields(index);
					}}
				/>
			</div>
			<Form.Item
				label={EAppFormFields.BLOCK_TYPE}
				name={[...path, index, EAppFormFields.BLOCK_TYPE]}
				initialValue={fieldData[EAppFormFields.BLOCK_TYPE]}
				rules={[{ min: 2, message: `${t('error.min_length')} 2` }]}
				hidden={true}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.BLOCK_TYPE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={t('title')}
				name={[...path, index, EAppFormFields.TITLE]}
				initialValue={fieldData[EAppFormFields.TITLE] ?? 'title'}
				rules={[
					{
						required: true,
						message: t('error.required_field')
					},
					{ validator: validateNotEmpty, message: t('error.cannot_only_spaces') },
					{ min: 2, message: `${t('error.min_length')} 2` }
				]}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.TITLE], e.target.value)} />
			</Form.Item>
			<Form.Item
				label={t('description')}
				name={[...path, index, EAppFormFields.DESCRIPTION]}
				initialValue={fieldData[EAppFormFields.DESCRIPTION]}
				rules={[]}
			>
				<Input onChange={(e) => onChange([...path, `${index}`, EAppFormFields.DESCRIPTION], e.target.value)} />
			</Form.Item>
		</div>
	);
};

export const TitleFields = memo(TitleFieldsComponent);
